import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AppLogo } from '../Resources/Images'
import { IsLoggedIn as isLoggedIn } from './auth/Auth';
import axios from 'axios';
import { LOGOUT } from '../routes';
import { ToastContainer, toast } from 'react-toastify';

const Header = () => {
    const { pathname } = useLocation();
    const [loggedInUser, setLoggedInUser] = useState();
    const navigate = useNavigate();
    const activeLink = (path) => {
        return [path].includes(pathname) ? 'nav-link active' : 'nav-link';
    }

    const destroySession = async () => {
        const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 1500));
        await axios.delete(LOGOUT);
        toast.promise(
            resolveAfter3Sec,
            {
                pending: 'Loading...',
                success: 'Logout Success',
                error: 'Error!'
            }
        );
        setTimeout(() => {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            navigate('/');
        }, 2500);
    }

    useEffect(() => {
        let user = localStorage.getItem('user');
        setLoggedInUser(JSON.parse(user));
    }, []);

    return (
        <nav className="navbar navbar-expand-lg b-bottom">
            <ToastContainer autoClose={1500} />
            <div className="container-fluid">
                <Link to={"/"} className="navbar-brand">
                    <img className="app-logo" src={AppLogo} height={60} width={60} alt="App Logo" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-label="Menus">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav" style={{ justifyContent: "space-between" }}>
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link to={"/admin"} className={activeLink('/admin')} aria-current="page">Dashboard</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={"/admin/orders"} className={activeLink('/admin/orders')} aria-current="page">Orders</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={"/admin/plan-reminders"} className={activeLink('/admin/plan-reminders')}>Plan Reminders</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={"/admin/customers"} className={activeLink('/admin/customers')}>Customers</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={"/admin/track-customer"} className={activeLink('/admin/track-customer')}>Track Customer</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link to={""} role="button" data-bs-toggle="dropdown" className="nav-link dropdown-toggle">Stores</Link>
                            <ul className="dropdown-menu" aria-labelledby="stores">
                                <li>
                                    <Link to={"/admin/stores/active"} className={activeLink('/admin/stores/active')}>Active Stores</Link>
                                </li>
                                <li>
                                    <Link to={"/admin/stores/in-active"} className={activeLink('/admin/stores/in-active')}>In-Active Stores</Link>
                                </li>
                                <li>
                                    <Link to={"/admin/stores/demo"} className={activeLink('/admin/stores/demo')}>Demo Store</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link to={"/admin/custom-plan"} className={activeLink('/admin/custom-plan')}>Custom Plan</Link>
                        </li>
                        
                    </ul>
                    <ul className="navbar-nav">
                        {isLoggedIn() ? <li className="nav-item dropdown">
                            <Link to={""} role="button" data-bs-toggle="dropdown" className="nav-link dropdown-toggle">Welcome! <b>{loggedInUser?.name}</b></Link>
                            <ul className="dropdown-menu profile" aria-labelledby="stores">
                                <li>
                                    <Link className="nav-link" aria-current="page" onClick={destroySession}>
                                        <i className="fa-solid fa-right-from-bracket"></i>&nbsp;Logout
                                    </Link>
                                </li>
                            </ul>
                        </li> : null}
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Header