/* eslint-disable no-unreachable */
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Card, CloseButton, Form } from 'react-bootstrap'
import { SAVE_CUSTOM_PLAN, GET_SHOPS, CUSTOM_PLAN_LIST, DELETE_CUSTOM_PLAN } from '../routes';
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';

const CustomPlan = () => {

    const [processing, setProcessing] = useState(false);

    const [isCheckedCampaign, setIsCheckedCampaign] = useState(false);
    const [isCheckedWalletPage, setIsCheckedWalletPage] = useState(false);
    const [isCheckedTierProgram, setIsCheckedTierProgram] = useState(false);

    const [shops, setShops] = useState([]);

    const [errors, setErrors] = useState([]);

    const [customPlansList, setCustomPlansList] = useState([]);
    const [modules, setModules] = useState([]);

    const [payload, setPayload] = useState({
        shop_id: '',
        order_count: '',
        price: '',
        accessible_modules: []
    });

    const loader = () => {
        return <tr>
            <td colSpan={11} className='text-center'>
                <div className="loader">
                    <svg viewBox="0 0 80 80">
                        <circle id="test" cx="40" cy="40" r="32"></circle>
                    </svg>
                </div>
            </td>
        </tr>
    };

    const options = [{ value: '', label: 'Select Store' }];

    const getSelectedStore = async (event = undefined, refetch = 0, refetch_ID = 0) => {
        if (refetch == 1 && refetch_ID != 0) {
            setProcessing(true);
            let res = await axios.post(CUSTOM_PLAN_LIST, { store: refetch_ID });
            setCustomPlansList(res.data.customPlans);
            setModules(Object.keys(res.data.modules));
            setProcessing(false);
        }
        else {
            setProcessing(true);
            let res = await axios.post(CUSTOM_PLAN_LIST, { store: event.value });
            setCustomPlansList(res.data.customPlans);
            setModules(Object.keys(res.data.modules));
            setProcessing(false);
        }
    }

    const storeHTML = (value) => {
        if (value.expire == 1) {
            return <>
                <br />
                <span className="text-danger">
                    <strong>
                        {value.expired_by == 'admin' ? 'Removed' : 'Expired'} by {value.expired_by}
                    </strong>
                </span>
            </>
        }
    }

    const displayModules = (plan, value) => {
        return plan.accessible_modules.split(',').includes(value) ? <i className="fa-solid fa-check"></i> : null;
    }

    const removePlan = async (id) => {
        if (window.confirm("Are you sure to remove this custom plan?")) {
            let res = await axios.post(DELETE_CUSTOM_PLAN, { id: id });
            if (res.data.status == 1 || res.data.status == 2) {
                if (res.data.status == 1) {
                    toast.success('Custom Plan is successfully removed by admin.');
                }
                if (res.data.status == 2) {
                    toast.info('Custom Plan is already removed.');
                }
            } else {
                if (res.data.status == 3) {
                    toast.warn('You can not remove this plan because this plan is running in Store');
                } else {
                    toast.warn('Something is wrong!');
                }
            }
            getSelectedStore('event', 1, id)
        }
    }

    const displayStatus = (plan) => {
        if (plan.expire == 1) {
            return <>
                <strong className="text-danger">{plan.expired_by == 'admin' ? 'Removed' : 'Expire'}</strong>
                <br /> {moment(plan.expire_at).format('YYYY-MM-DD')}
            </>
        } else {
            return <strong>{plan.paid_status == 0 ? 'Pending' : 'Paid/Active'}</strong>
        }
        return <>
            <br />
            {plan.paid_date !== '' && plan.paid_date != null ? (plan.paid_date) : null}
        </>
    }

    const customPlanListHTML = customPlansList?.length ? customPlansList.map(plan =>
        <tr key={plan.id}>
            <td>
                {plan.store.shop}
                {storeHTML(plan)}
            </td>
            <td>{plan.order_count}</td>
            <td>{plan.price}</td>
            {modules.length && modules.map((value, index) => {
                return (
                    <td key={index}>
                        {displayModules(plan, value)}
                    </td>
                )
            })}
            <td>{moment(plan.created_at).format('YYYY-MM-DD')}</td>
            <td>
                {displayStatus(plan)}
            </td>
            <td>{moment(plan.paid_date).format('YYYY-MM-DD')}</td>
            <td className="text-center">
                {plan.display_status == '1' ? <i className="fa-solid fa-check"></i> : <i className="fa-regular fa-circle-xmark"></i>}
            </td>
            <td>
                <div className='text-center'>
                    <CloseButton disabled={plan.expire == 1 || plan.paid_status == 1 ? true : false} onClick={() => removePlan(plan.id)} />
                </div>
            </td>
        </tr >
    ) :
        <tr>
            <td colSpan={11} className='text-center'>No data found!</td>
        </tr>;

    const getShops = async () => {
        const result = await axios.post(GET_SHOPS);
        setShops(result.data);
    };

    shops && shops.map(value =>
        options.push({
            value: value.id, label: value.shop
        })
    );

    const createCustomPlan = async () => {
        let accessible_modules = [];
        if (isCheckedCampaign)
            accessible_modules.push('all_campaigns');
        if (isCheckedWalletPage)
            accessible_modules.push('wallet_page');
        if (isCheckedTierProgram)
            accessible_modules.push('tier_program');

        let data = {
            ...payload, accessible_modules: accessible_modules
        }

        const res = await axios.post(SAVE_CUSTOM_PLAN, { data: data });
        if (res.data.errors) {
            setErrors(res.data.errors);
        } else {
            toast.success(res.data.message);
            setPayload({
                shop_id: '',
                order_count: '',
                price: '',
                accessible_modules: []
            });
            setIsCheckedCampaign(false);
            setIsCheckedWalletPage(false);
            setIsCheckedTierProgram(false);
            setErrors([]);
        }
    }

    const addRemove = (e, type) => {
        if (type === 'Campaign') {
            setIsCheckedCampaign(!isCheckedCampaign);
        }
        if (type === 'Wallet Page') {
            setIsCheckedWalletPage(!isCheckedWalletPage);
        }
        if (type === 'Tier Program') {
            setIsCheckedTierProgram(!isCheckedTierProgram);
        }
    }

    useEffect(() => {
        getShops();
    }, []);

    return (
        <div className="container mt-4">
            <ToastContainer autoClose={1500} />
            <div className="row">
                <Card>
                    <Card.Header>
                        <h6 className='my-0'>Create Custom Plan</h6>
                    </Card.Header>
                    <Card.Body>
                        <div className="row">
                            <div className="col-md-4">
                                <strong>Stores</strong>
                                {shops.length ?
                                    <Select options={options} onChange={(e) => setPayload({ ...payload, shop_id: e.value })} />
                                    : <div id="loader"></div>
                                }
                            </div>
                            <div className="col-md-4">
                                <strong>Order Count Per Month</strong>
                                <Form.Control isInvalid={errors && errors.order_count} value={payload.order_count} type="text" placeholder="Enter order count" onChange={(e) => setPayload({ ...payload, order_count: e.target.value })} />
                            </div>
                            <div className="col-md-4">
                                <strong>Price</strong>
                                <Form.Control isInvalid={errors && errors.price} type="text" value={payload.price} placeholder="Enter order price" onChange={(e) => setPayload({ ...payload, price: e.target.value })} />
                            </div>
                        </div>
                        {errors ? <div className="row error">
                            <div className="col-md-4 text-danger">
                                {errors.shop_id ? errors.shop_id[0] : null}
                            </div>
                            <div className="col-md-4 text-danger">
                                {errors.order_count ? errors.order_count[0] : null}
                            </div>
                            <div className="col-md-4 text-danger">
                                {errors.price ? errors.price[0] : null}
                            </div>
                        </div> : null}
                        <div className='row mt-4'>
                            <div className="col-md-4">
                                <strong>Access All Campaigns</strong>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={isCheckedCampaign}
                                    onChange={(e) => addRemove(e, 'Campaign')}
                                />
                            </div>
                            <div className="col-md-4">
                                <strong>Access My Wallet Page</strong>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={isCheckedWalletPage}
                                    onChange={(e) => addRemove(e, 'Wallet Page')}
                                />
                            </div>
                            <div className="col-md-4">
                                <strong>Access Tier Program</strong>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={isCheckedTierProgram}
                                    onChange={(e) => addRemove(e, 'Tier Program')}
                                />
                            </div>
                        </div>
                        {shops.length ? <Button className='mt-4 float-end' size='sm' variant="primary" onClick={createCustomPlan}>Save</Button> : <div id="loader" className="float-end"></div>}
                    </Card.Body>
                </Card>
            </div>
            <div className="row mt-4">
                <Card>
                    <Card.Header>
                        <h6 className='my-0'>Custom Plan List</h6>
                    </Card.Header>
                    <Card.Body>
                        <div className="row">
                            <div className="col-md-4">
                                <strong>Select store to show custom plan summery</strong>
                                {shops.length ?
                                    <Select options={options} onChange={(e) => getSelectedStore(e)} />
                                    : <div id="loader"></div>
                                }
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className="box">
                                <div className="box-body">
                                    <div className='table-responsive-lg'>
                                        <table className="table sub-table table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className='col-1'>Store</th>
                                                    <th scope="col" className='col-1'>Order</th>
                                                    <th scope="col" className='col-1'>Price</th>
                                                    <th scope="col" className='col-1'>All Campaigns</th>
                                                    <th scope="col" className='col-1'>Wallet Page</th>
                                                    <th scope="col" className='col-1'>Tier Program</th>
                                                    <th scope="col" className='col-1'>Created Date</th>
                                                    <th scope="col" className='col-1'>Status</th>
                                                    <th scope="col" className='col-1'>Paid Date</th>
                                                    <th scope="col" className='col-1'>Display</th>
                                                    <th scope="col" className='col-1'>Remove</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {processing ? loader() : customPlanListHTML}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}

export default CustomPlan