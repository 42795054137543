import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FETCH_CASHBACK_LIST, GET_DEMO_STORES } from '../routes';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const DemoStores = () => {
  const [fetching, setFetching] = useState(false);
  const [stores, setActiveStores] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [cashbackCampaigns, setCashbackCampaigns] = useState([]);
  const navigate = useNavigate();

  const loader = () => {
    return (
      <tr>
        <td colSpan={10} className='text-center'>
          <div className="loader">
            <svg viewBox="0 0 80 80">
              <circle id="test" cx="40" cy="40" r="32"></circle>
            </svg>
          </div>
        </td>
      </tr>
    );
  };

  const getActiveStores = async () => {
    setFetching(true);
    const result = await axios.post(GET_DEMO_STORES);
    setActiveStores(result);
    setFetching(false);
  }

  const formatDateTime = (value) => {
    return moment(value).format('Do MMM YYYY, h:mm:ss a');
  };

  const cashbackCampaign = (
    <Tooltip id="cashbackCampaign">
      <strong>Cashback Campaign</strong>
    </Tooltip>
  );

  const activityLog = (
    <Tooltip id="activityLog">
      <strong>Activity Logs</strong>
    </Tooltip>
  );

  const getCashbackCampaign = async (shop_id) => {
    setProcessing(true);
    const result = await axios.post(FETCH_CASHBACK_LIST, {
      shop_id: shop_id
    });
    setCashbackCampaigns(result);
    setProcessing(false);
  }

  const showCashbackCampaign = (shop_id) => {
    getCashbackCampaign(shop_id);
  }

  const cashbackCampaignHTML = cashbackCampaigns.data && cashbackCampaigns.data.data.length ? cashbackCampaigns.data.data.map(value =>
    <tr key={value.id}>
      <td>{value.name}</td>
      {value.type == '1' ? <td>Cashback on Facebook Share</td> : ''}
      {value.type == '2' ? <td>Cashback on Instagram Follow</td> : ''}
      {value.type == '3' ? <td>Cashback on Twitter Retweet</td> : ''}
      {value.type == '4' ? <td>Cashback on Total Purchase</td> : ''}
      {value.type == '5' ? <td>Cashback on Customer Group</td> : ''}
      {value.type == '6' ? <td>Cashback on Create Account</td> : ''}
      {value.type == '7' ? <td>Cashback Shipping Charges</td> : ''}
      {value.type == '8' ? <td>Cashback on Referral</td> : ''}
      {value.type == '9' ? <td>Cashback on LinkedIn Share</td> : ''}
      {value.type == '10' ? null : ''}
      {value.type == '0' ? <td>Cashback on Products OR Collections</td> : ''}
      <td>{value.campaign_start_time ? formatDateTime(value.campaign_start_time) : '--'}</td>
      <td>{value.campaign_end_time ? formatDateTime(value.campaign_end_time) : '--'}</td>
      <td>{value.apply_type}</td>
      <td>{value.apply_type_value}</td>
      <td>{value.apply_on ? value.apply_on : '--'}</td>
      <td>{value.max_cashback_amount ? value.max_cashback_amount : '--'}</td>
      <td>{value.active ? <span style={{ color: "#04c504" }}>Active</span> : <span style={{ color: "red" }}>Inactive</span>}</td>
    </tr>
  ) :
    <tr>
      <td colSpan={10} className='text-center'>
        No data found!
      </td>
    </tr>;

  const generateRawHtml = stores.data && stores.data.shops.length ? stores.data.shops.map(store =>
    <tr key={store.id}>
      <td>
        <OverlayTrigger placement="bottom" overlay={cashbackCampaign}>
          <i className="fa-solid fa-list" data-bs-toggle="modal" data-bs-target="#cashbackCampaign" onClick={() => showCashbackCampaign(store.id)}></i>
        </OverlayTrigger>&nbsp;&nbsp;
        <OverlayTrigger placement="bottom" overlay={activityLog}>
          <i className="fa-solid fa-clock-rotate-left" onClick={() => {
            navigate('/admin/stores/activity/' + store.id);
          }}></i>
        </OverlayTrigger>
        <div className="modal fade" id="cashbackCampaign" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Cashback Campaigns</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="box mt-2">
                  <div className="box-body">
                    <div className='table-responsive-lg'>
                      <table className="table sub-table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th scope="col" className='col-1'>Campaign Name</th>
                            <th scope="col" className='col-1'>Campaign Type</th>
                            <th scope="col" className='col-1'>Campaign Start Date</th>
                            <th scope="col" className='col-1'>Campaign End Date</th>
                            <th scope="col" className='col-1'>Apply Type</th>
                            <th scope="col" className='col-1'>Apply Type Value</th>
                            <th scope="col" className='col-1'>Apply On</th>
                            <th scope="col" className='col-1'>Max Cashback Amount</th>
                            <th scope="col" className='col-1'>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {processing ? loader() : cashbackCampaignHTML}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
      <td><span style={{ color: "#04c504" }}>Active</span></td>
      <td>https://{store.shop}</td>
      <td>{formatDateTime(store.created_at)}</td>
      <td>{store?.plan?.plan_name ? store?.plan?.plan_name : 'N/A'}</td>
      <td>{store.plan_start_at ? formatDateTime(store.plan_start_at) : '-'}</td>
      <td>{store.plan_end_at ? formatDateTime(store.plan_end_at) : '-'}</td>
      <td>{store.shop_email ? store.shop_email : '-'}</td>
      <td>{store.shop_phone ? store.shop_phone : '-'}</td>
      <td>{store.shop_country_name ? store.shop_country_name : 'N/A'}</td>
    </tr>
  ) :
    <tr>
      <td colSpan={10} className='text-center'>
        No data found!
      </td>
    </tr>;

  useEffect(() => {
    getActiveStores();
  }, []);

  return (
    <div className='container'>
      <div className="row mt-3">
        <h4>Demo Stores</h4>
        <div className="col-lg-12">
          <div className="box mt-2">
            <div className="box-body">
              <div className='table-responsive-lg'>
                <table className="table sub-table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" className='col-1'>Action</th>
                      <th scope="col" className='col-1'>Status</th>
                      <th scope="col" className='col-1'>Store Name</th>
                      <th scope="col" className='col-1'>Installation Date</th>
                      <th scope="col" className='col-1'>Plan Name</th>
                      <th scope="col" className='col-1'>Plan Start Date</th>
                      <th scope="col" className='col-1'>Plan End Date</th>
                      <th scope="col" className='col-1'>Email</th>
                      <th scope="col" className='col-1'>Phone</th>
                      <th scope="col" className='col-1'>Country</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fetching ? loader() : generateRawHtml}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DemoStores