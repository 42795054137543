// const base_url = "http://127.0.0.1:8000";
// const base_url = "https://cashback.webplanex.com";
const base_url = "https://cashbackdemo.webplanex.com";

const LOGIN = base_url + "/api/login";
const CHECK_AUTH = base_url + "/api/check-auth";
const LOGOUT = base_url + "/api/logout";
const GET_ORDERS = base_url + "/api/get-orders";
const GET_CUSTOMERS = base_url + "/api/get-customers";
const PROCESS_ORDER = base_url + "/api/process-order";
const PROCESS_CUSTOMER = base_url + "/api/process-customer";
const GET_SHOPS = base_url + "/api/get-shops";
const GET_FAILED_ORDERS = base_url + "/api/get-failed-orders";
const PROCESS_FAILED_ORDER = base_url + "/api/process-failed-order";
const GET_TRACKED_DATA = base_url + "/api/get-tracked-data";
const GET_ACTIVE_STORES = base_url + "/api/get-active-stores";
const GET_INACTIVE_STORES = base_url + "/api/get-in-active-stores";
const GET_DEMO_STORES = base_url + "/api/get-demo-stores";
const FETCH_CASHBACK_LIST = base_url + "/api/fetch-cashback-list";
const GET_ACTIVITY_LOGS = base_url + "/api/get-activity-logs";
const SAVE_CUSTOM_PLAN = base_url + "/api/save-custom-plan";
const CUSTOM_PLAN_LIST = base_url + "/api/custom-plan-list";
const DELETE_CUSTOM_PLAN = base_url + "/api/delete-custom-plan";
const GET_EXHUST_STORE_LIST = base_url + "/api/get-exhust-store-list";
const DELETE_FAILED_ORDER_DB = base_url + "/api/delete-failed-order-db";
const MARK_ORDER_AS_PROCESSED = base_url + "/api/mark-order-as-processed";
const GET_DASHBOARD_DATA = base_url + "/api/get-dashboard-data";
const GET_COUNTS = base_url + "/api/get-counts";
const SEND_REMINDER_EMAIL = base_url + "/api/send-reminder-email";

export {
    LOGIN,
    CHECK_AUTH,
    LOGOUT,
    GET_ORDERS,
    GET_CUSTOMERS,
    PROCESS_ORDER,
    PROCESS_CUSTOMER,
    GET_SHOPS,
    GET_FAILED_ORDERS,
    PROCESS_FAILED_ORDER,
    GET_TRACKED_DATA,
    GET_ACTIVE_STORES,
    GET_INACTIVE_STORES,
    GET_DEMO_STORES,
    FETCH_CASHBACK_LIST,
    GET_ACTIVITY_LOGS,
    SAVE_CUSTOM_PLAN,
    CUSTOM_PLAN_LIST,
    DELETE_CUSTOM_PLAN,
    GET_EXHUST_STORE_LIST,
    DELETE_FAILED_ORDER_DB,
    MARK_ORDER_AS_PROCESSED,
    GET_DASHBOARD_DATA,
    GET_COUNTS,
    SEND_REMINDER_EMAIL
};