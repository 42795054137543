import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { GET_EXHUST_STORE_LIST } from '../routes';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import MailModal from './MailModal';

const PlanReminders = () => {

    const [processing, setProcessing] = useState(false);

    const [stores, setStores] = useState([]);

    const [storeDetails, setStoreDetails] = useState(null);

    const [modalShow, setModalShow] = useState(false);

    const loader = () => {
        return <tr>
            <td colSpan={9} className='text-center'>
                <div className="loader">
                    <svg viewBox="0 0 80 80">
                        <circle id="test" cx="40" cy="40" r="32"></circle>
                    </svg>
                </div>
            </td>
        </tr>
    }

    const [page, setPage] = useState(1);

    const tooltip = (
        <Tooltip id="tooltip">
            <strong>Copy Response</strong>
        </Tooltip>
    );

    const sendEmail = (
        <Tooltip id="sendEmail">
            <strong>Send Email</strong>
        </Tooltip>
    );

    const copyToClipboard = (order_id) => {
        var copyText = document.getElementById("response_text" + order_id);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        toast("Copied!");
    };

    const generateRawHtml = stores.data?.map(store =>
        <tr key={store.id}>
            <td className='text-center'>
                <OverlayTrigger placement="bottom" overlay={sendEmail}>
                    <i className="fa-solid fa-envelope" style={{ cursor: "pointer" }} onClick={async () => {
                        setStoreDetails(store);
                        setModalShow(true);
                    }}></i>
                </OverlayTrigger>
                &nbsp;&nbsp;
                {store.plan_active_response !== '' ?
                    <>
                        <OverlayTrigger placement="bottom" overlay={tooltip}>
                            <i className="fa-solid fa-copy" onClick={() => copyToClipboard(store.id)}></i>
                        </OverlayTrigger>
                        <textarea id={"response_text" + store.id} style={{ display: "none" }} defaultValue={store.plan_active_response}></textarea>
                    </>
                    : ""
                }
               
                
            </td>
            <td>{store.mail_send_count.length}</td>
            <td>{store.shop}</td>
            <td>{store.shop_customer_email ? store.shop_customer_email : ''}</td>
            <td>{store.shop_phone ? store.shop_phone : ''}</td>
            <td>{store.shop_country ? store.shop_country : ''}</td>
            <td>{store.plan ? store.plan.plan_name : '-'} ({store.plan.plan_name === 'Custom' ? store.custom_plans[0]?.order_count : store.plan ? store.plan.order_count : '-'})</td>
            <td><span style={{ color: store.plan_order_count > store.plan.order_count ? 'red' : 'inherit' }}>{store.plan_order_count}</span></td>
            <td>{store?.usage_base_shop === 0 ? 'No' : 'Yes'}</td>
        </tr>
    );

    const getExhustStoreList = async () => {
        setProcessing(true);
        let res = await axios.post(GET_EXHUST_STORE_LIST);
        setStores(res.data);
        setProcessing(false);
    }

    const paginationTooltipPrev = (
        <Tooltip id="paginationTooltipPrev">
            <strong>Prev</strong>
        </Tooltip>
    );

    const paginationTooltipNext = (
        <Tooltip id="paginationTooltipNext">
            <strong>Next</strong>
        </Tooltip>
    );

    const handleNextPage = async () => {
        if (stores.next_page_url !== null) {
            setStores([]);
            setProcessing(true);
            let result = await axios.post(GET_EXHUST_STORE_LIST + "?page=" + parseInt(page + 1));
            setStores(result.data);
            setProcessing(false);

        }
    };

    const handlePrevPage = async () => {
        if (stores.prev_page_url !== null) {
            setStores([]);
            setProcessing(true);
            let result = await axios.post(GET_EXHUST_STORE_LIST + "?page=" + parseInt(page - 1));
            setStores(result.data);
            setProcessing(false);

        }
    };

    useEffect(() => {
        if (page !== 0) {
            getExhustStoreList();
        }
    }, [page]);

    return (
        <div className="container">
            <ToastContainer autoClose={1500} />
            <div className="box mt-5">
                <div className="box-body">
                    <div className='table-responsive-lg'>
                        <table className="table sub-table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-center">Actions</th>
                                    <th scope="col">Mail Sent Count</th>
                                    <th scope="col">Store Name</th>
                                    <th scope="col">Store Email</th>
                                    <th scope="col">Store Phone</th>
                                    <th scope="col">Store Country</th>
                                    <th scope="col">Current Plan</th>
                                    <th scope="col">Order Count</th>
                                    <th scope="col">Is Usage Base</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stores.length === 0 ? loader() : generateRawHtml}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {!processing ? <div className="box-footer">
                <nav>
                    <ul className="pagination">
                        <li className="page-item">
                            <OverlayTrigger placement="bottom" overlay={paginationTooltipPrev}>
                                <span className="page-link" onClick={handlePrevPage}>
                                    <span aria-hidden="true">&laquo;</span>
                                </span>
                            </OverlayTrigger>
                        </li>
                        <li className="page-item">
                            <OverlayTrigger placement="bottom" overlay={paginationTooltipNext}>
                                <span className="page-link" onClick={handleNextPage}>
                                    <span aria-hidden="true">&raquo;</span>
                                </span>
                            </OverlayTrigger>
                        </li>
                    </ul>
                </nav>
            </div> : null}
            <MailModal
                show={modalShow}
                onHide={() => {
                    getExhustStoreList();
                    setModalShow(false);
                }}
                storedata={storeDetails}
            />
        </div>
    )
}

export default PlanReminders