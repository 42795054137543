import { Navigate, Outlet } from 'react-router-dom';
import { IsLoggedIn as isLoggedIn } from './auth/Auth';

const StoresLayout = () => {
    return (
        <div className="container">
            <Outlet />
        </div>
    );
}

export default StoresLayout