import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { DELETE_FAILED_ORDER_DB, GET_COUNTS, GET_FAILED_ORDERS, GET_ORDERS, GET_SHOPS, MARK_ORDER_AS_PROCESSED, PROCESS_FAILED_ORDER, PROCESS_ORDER } from '../routes'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment/moment';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select'

const Order = () => {

	const [orders, setOrders] = useState([]);

	const [counts, setCounts] = useState({
		'failedOrderCount': '',
		'currentlyProcessingOrderCount': '',
		'unprocessedOrderCount': ''
	});

	const [failedOrders, setFailedOrders] = useState([]);

	const searchBtn = useRef();

	const [processing, setProcessing] = useState(false);

	const [failedOrderProcessing, setFailedOrderProcessing] = useState(false);

	const [filters, setFilters] = useState({
		search: '',
		status: '',
		shop_id: ''
	});

	const [page, setPage] = useState(1);


	const [shops, setShops] = useState([]);

	const getOrders = async () => {
		setOrders([]);
		setProcessing(true);
		await axios.post(GET_ORDERS + "?page=" + page).then((result) => {
			setOrders(result.data.orders);
		}).then(() => {
			setProcessing(false);
		});
	};

	const copyToClipboard = (order_id) => {
		var copyText = document.getElementById("response_text" + order_id);
		copyText.select();
		copyText.setSelectionRange(0, 99999);
		navigator.clipboard.writeText(copyText.value);
		toast("Copied!");
	};

	const handleNextPage = async () => {
		if (orders.next_page_url !== null) {
			setOrders([]);
			setProcessing(true);
			let result = await axios.post(GET_ORDERS + "?page=" + parseInt(page + 1) + "&filters=" + JSON.stringify(filters));
			setOrders(result.data.orders);
			setProcessing(false);

		}
	};

	const handlePrevPage = async () => {
		if (orders.prev_page_url !== null) {
			setOrders([]);
			setProcessing(true);
			let result = await axios.post(GET_ORDERS + "?page=" + parseInt(page - 1) + "&filters=" + JSON.stringify(filters));
			setOrders(result.data.orders);
			setProcessing(false);

		}
	};

	const tooltip = (
		<Tooltip id="tooltip">
			<strong>Copy Response</strong>
		</Tooltip>
	);

	const paginationTooltipPrev = (
		<Tooltip id="paginationTooltipPrev">
			<strong>Prev</strong>
		</Tooltip>
	);

	const paginationTooltipNext = (
		<Tooltip id="paginationTooltipNext">
			<strong>Next</strong>
		</Tooltip>
	);

	const processOrderTooltip = (
		<Tooltip id="processOrderTooltip">
			<strong>Process Order</strong>
		</Tooltip>
	);

	const markAsProcessed = (
		<Tooltip id="markAsProcessed">
			<strong>Mark As Processed</strong>
		</Tooltip>
	);

	const notPresentInOrder = (
		<Tooltip id="notPresentInOrder">
			<strong>Not present in order</strong>
		</Tooltip>
	);

	const formatDateTime = (value) => {
		return moment(value).format('Do MMM YYYY, h:mm:ss a');
	};

	const performFilter = async () => {
		setOrders([]);
		setProcessing(true);
		let payload = false;
		if (filters.search !== '' || filters.status !== '' || filters.shop_id !== '') {
			payload = true;
		} else {
			payload = false;
		}
		let URL = payload ? "&filters=" + JSON.stringify(filters) : '';
		let result = await axios.post(GET_ORDERS + "?page=" + page + URL);
		setOrders(result.data.orders);
		if (filters.shop_id !== '') {
			getCounts();
		}
		setProcessing(false);
	};

	const processOrder = async (order_main_id) => {
		setOrders([]);
		setProcessing(true);
		let res = await axios.post(PROCESS_ORDER, { order_main_id: order_main_id });
		if (res.data.status === 1) {
			toast.success(res.data.message);
		} else {
			toast.error(res.data.message);
		}
		setProcessing(false);
		getOrders();
		getCounts();
	};

	const processFailedOrder = async (order_raw_id) => {
		setFailedOrderProcessing(true);
		let res = await axios.post(PROCESS_FAILED_ORDER, { id: order_raw_id });
		if (res.data.status === 1) {
			toast.success(res.data.message);
		} else {
			toast.error(res.data.message);
		}
		setFailedOrderProcessing(false);
		await setPage(0);
		setPage(1);
	};

	const resetFilter = async () => {
		setFilters({
			search: '',
			status: '',
			shop_id: '',
		});
		setDummyOptions({ value: '', label: 'Select Store' });
		getCounts();
		await setPage(0);
		setPage(1);
	};

	const getFailedOrders = async () => {
		setFailedOrderProcessing(true);
		const result = await axios.post(GET_FAILED_ORDERS);
		await setFailedOrders(result);
		setFailedOrderProcessing(false);
	}

	const markAsProcessedDB = async (order_id) => {
		setOrders([]);
		setProcessing(true);
		let res = await axios.post(MARK_ORDER_AS_PROCESSED, { order_id: order_id });
		if (res.data.status === 1) {
			toast.success('Order marked as processed');
		} else {
			toast.success('Something went wrong');
		}
		setProcessing(false);
		getOrders();
		getCounts();
	}

	const generateRawHtml = orders.data?.map(order =>
		<tr key={order.id}>
			<td className='text-center'>
				<OverlayTrigger placement="bottom" overlay={tooltip}>
					<i className="fa-solid fa-copy" onClick={() => copyToClipboard(order.id)}></i>
				</OverlayTrigger>&nbsp;&nbsp;
				<textarea id={"response_text" + order.id} style={{ display: "none" }} defaultValue={order.order_json}></textarea>
				{
					order.oreder_save_status === 0 ?
						order.order_main_id === order.order?.order_main_id ?
							<OverlayTrigger placement="bottom" overlay={markAsProcessed}>
								<i className="fa-solid fa-check text-danger" onClick={() => markAsProcessedDB(order.id)} style={{ cursor: "pointer" }}></i>
							</OverlayTrigger> :
							<OverlayTrigger placement="bottom" overlay={processOrderTooltip}>
								<i className="fa-solid fa-microchip text-danger" onClick={() => processOrder(order.order_main_id)}></i>
							</OverlayTrigger>
						: null
				}
				{
					order.oreder_save_status === 1 ?
						order.order_main_id === order.order?.order_main_id ?
							<i className="fa-solid fa-check text-success" style={{ cursor: "pointer" }}></i> :
							<OverlayTrigger placement="bottom" overlay={notPresentInOrder}>
								<i className="fa-solid fa-microchip text-danger"></i>
							</OverlayTrigger>
						: null
				}
			</td>
			<td>{order.shop_id}</td>
			<td>{order.job_id}</td>
			<td>{order.shop}</td>
			<td>
				{order.order_name}<br />
				<small><b>({order.id})</b></small>
			</td>
			<td>{order.order_main_id}</td>
			<td>{order.order_date}</td>
			<td className='text-center'>
				{order.financial_staus === 'paid' ? <span className="badge bg-success">Paid</span> : (order.financial_staus === 'pending' ? <span className="badge bg-warning">Pending</span> : '-')}
			</td>
			<td className='text-center'>
				{order.fulfillment_status === 'fulfilled' ? <span className="badge bg-secondary">Fulfilled</span> : <span className="badge bg-secondary">Unfulfilled</span>}
			</td>
			<td>
				{order.oreder_save_status === 1 ? <span className="badge bg-success">Processed</span> : <span className="badge bg-warning">Unprocessed</span>}
			</td>
			<td>{formatDateTime(order.created_at)}</td>
		</tr>
	);

	const deleteFailedTooltip = (
		<Tooltip id="deleteFailedTooltip">
			<strong>Delete Failed Order</strong>
		</Tooltip>
	);

	const deleteFailedOrderBD = async (order_id) => {
		let res = await axios.post(DELETE_FAILED_ORDER_DB, { id: order_id });
		if (res.data.status === 1) {
			toast.success('Order deleted successfully!');
			getFailedOrders();
		} else {
			toast.warn('Somwthing went wrong!');
		}
	}

	const generateRawHtmlFailedOrders = failedOrders.data?.map(order =>
		<tr key={order.id}>
			<td className='text-center'>
				<OverlayTrigger placement="bottom" overlay={tooltip}>
					<i className="fa-solid fa-copy" onClick={() => copyToClipboard(order.id)}></i>
				</OverlayTrigger>&nbsp;&nbsp;
				<textarea id={"response_text" + order.id} style={{ display: "none" }} defaultValue={order.exception}></textarea>
				<OverlayTrigger placement="bottom" overlay={processOrderTooltip}>
					<i className="fa-solid fa-microchip" onClick={() => processFailedOrder(order.order_row_id)}></i>
				</OverlayTrigger>&nbsp;&nbsp;
				<OverlayTrigger placement="bottom" overlay={deleteFailedTooltip}>
					<i className={order.is_processed ? "fa-solid fa-trash text-danger" : "fa-solid fa-trash"} onClick={() => deleteFailedOrderBD(order.id)} style={{ cursor: "pointer" }}></i>
				</OverlayTrigger>
			</td>
			<td>{order.id}</td>
			<td>{order.order_row_id}</td>
			<td>{formatDateTime(order.failed_at)}</td>
		</tr>
	);

	const loader = () => {
		return <tr>
			<td colSpan={11} className='text-center'>
				<div className="loader">
					<svg viewBox="0 0 80 80">
						<circle id="test" cx="40" cy="40" r="32"></circle>
					</svg>
				</div>
			</td>
		</tr>
	};

	const resetFailedOrders = () => {
		setFailedOrders([]);
	}

	const searchTooltip = (
		<Tooltip id="searchTooltip">
			<strong>Search</strong>
		</Tooltip>
	);

	const resetTooltip = (
		<Tooltip id="resetTooltip">
			<strong>Reset</strong>
		</Tooltip>
	);

	const refreshToolTip = (
		<Tooltip id="refreshToolTip">
			<strong>Refresh</strong>
		</Tooltip>
	);

	let options = [{ value: '', label: 'Select Store' }];

	const [dummyOptions, setDummyOptions] = useState({ value: '', label: 'Select Store' });

	const refreshData = () => {
		searchBtn.current.click();
	}

	const getCounts = async () => {
		setProcessing(true);
		let params = filters.shop_id !== '' ? "?filters=" + JSON.stringify(filters) : '';
		let result = await axios.post(GET_COUNTS + params);
		setCounts({
			'failedOrderCount': result.data.failedOrderCount,
			'currentlyProcessingOrderCount': result.data.currentlyProcessingOrderCount,
			'unprocessedOrderCount': result.data.unprocessedOrderCount
		})
		setProcessing(false);
	}

	const getShops = async () => {
		const result = await axios.post(GET_SHOPS);
		setShops(result.data);
	};

	shops && shops.map(value =>
		options.push({
			value: value.id, label: value.shop
		})
	);

	useEffect(() => {
		if (page !== 0) {
			getOrders();
			getCounts();
			getShops();
		}
	}, [page])

	return (
		<div className="container">
			<ToastContainer autoClose={1500} />
			<div className="filters">
				<div className="row mt-3 shop-list">
					<div className="col-md-3 col-lg-3 col-sm-6">
						{shops.length ?
							<Select options={options} value={dummyOptions} onChange={(event) => {
								setFilters({ ...filters, shop_id: event.value })
								setDummyOptions({ value: event.value, label: event.label });
							}} />
							: <div id="loader"></div>
						}
					</div>
					<div className="col-md-3 col-lg-3 col-sm-6">
						<input type="text" className="form-control" placeholder="Search" value={filters.search} onChange={(event) => setFilters({ ...filters, search: event.target.value })} />
					</div>
					<div className="col-md-3 col-lg-3 col-sm-6">
						<select className="form-select" value={filters.status} onChange={(event) => setFilters({ ...filters, status: event.target.value })}>
							<option value="">Select Status</option>
							<option value={1}>Processed</option>
							<option value={0}>Unprocessed</option>
						</select>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-md-12 col-lg-12 col-sm-12">
						<button className="btn btn-primary btn-sm" type="button" onClick={performFilter} ref={searchBtn}>
							<OverlayTrigger placement="bottom" overlay={searchTooltip}>
								<i className="fa-solid fa-magnifying-glass"></i>
							</OverlayTrigger>
						</button>&nbsp;
						<button className="btn btn-primary btn-sm" type="button" onClick={refreshData}>
							<OverlayTrigger placement="bottom" overlay={refreshToolTip}>
								<i className="fa-solid fa-arrows-rotate"></i>
							</OverlayTrigger>
						</button>&nbsp;
						<button className="btn btn-danger btn-sm" type="button" onClick={resetFilter}>
							<OverlayTrigger placement="bottom" overlay={resetTooltip}>
								<i className="fa-solid fa-rotate-right"></i>
							</OverlayTrigger>
						</button>&nbsp;
						{
							failedOrders.length <= 0 ?
								(<button className="btn btn-danger btn-sm" type="button" onClick={getFailedOrders}>
									<i className="fa-regular fa-circle-xmark"></i> Failed Orders
								</button>)
								: null
						}
					</div>
				</div>
			</div>
			<div className="row mt-3">
				<div className="col-md-4">
					<label>
						{counts.unprocessedOrderCount !== '' ?
							<>Unprocessed Orders : <strong>{counts.unprocessedOrderCount}</strong></>
							: <div id="loader"></div>}
					</label>
				</div>
				<div className="col-md-4">
					<label>
						{counts.failedOrderCount !== '' ?
							<>Failed Orders : <strong>{counts.failedOrderCount}</strong></>
							: <div id="loader"></div>}
					</label>
				</div>
				<div className="col-md-4">
					<label>
						{counts.currentlyProcessingOrderCount !== '' ?
							<>Currently Processing Orders : <strong>{counts.currentlyProcessingOrderCount}</strong></>
							: <div id="loader"></div>}
					</label>
				</div>
			</div>
			{failedOrders.data?.length > 0 ?
				<div className="box mt-3" style={{ position:'relative' }}>
					<div className="box-body">
						<div className='table-responsive-lg'>
							<i className="fa-solid fa-xmark" onClick={resetFailedOrders}></i>
							<table className="table sub-table table-striped table-bordered">
								<thead>
									<tr>
										<th scope="col" className='col-1 text-center'>Actions</th>
										<th scope="col" className='col-1'>ID</th>
										<th scope="col" className='col-1'>Order Row ID</th>
										<th scope="col" className='col-1'>Failed At</th>
									</tr>
								</thead>
								<tbody>
									{failedOrderProcessing ? loader() : generateRawHtmlFailedOrders}
								</tbody>
							</table>
						</div>
					</div>
				</div> : null
			}
			<div className="box mt-3">
				<div className="box-body">
					<div className='table-responsive-lg'>
						<table className="table sub-table table-striped table-bordered">
							<thead>
								<tr>
									<th scope="col" className='col-1'>Actions</th>
									<th scope="col" className='col-1'>Shop ID</th>
									<th scope="col" className='col-1'>Job ID</th>
									<th scope="col" className='col-1'>Store Name</th>
									<th scope="col" className='col-1'>Order Name</th>
									<th scope="col" className='col-1'>Order Main ID</th>
									<th scope="col" className='col-1'>Order Date</th>
									<th scope="col" className='col-1'>Financial Status</th>
									<th scope="col" className='col-1'>Fulfillment Status</th>
									<th scope="col" className='col-1 text-center'>Status</th>
									<th scope="col" className='col-1'>Created On</th>
								</tr>
							</thead>
							<tbody>
								{orders.length === 0 ? loader() : generateRawHtml}
							</tbody>
						</table>
					</div>
				</div>
				{!processing ? <div className="box-footer">
					<nav>
						<ul className="pagination">
							<li className="page-item">
								<OverlayTrigger placement="bottom" overlay={paginationTooltipPrev}>
									<span className="page-link" onClick={handlePrevPage}>
										<span aria-hidden="true">&laquo;</span>
									</span>
								</OverlayTrigger>
							</li>
							<li className="page-item">
								<OverlayTrigger placement="bottom" overlay={paginationTooltipNext}>
									<span className="page-link" onClick={handleNextPage}>
										<span aria-hidden="true">&raquo;</span>
									</span>
								</OverlayTrigger>
							</li>
						</ul>
					</nav>
				</div> : null}
			</div>
		</div>
	)
}

export default Order